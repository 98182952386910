body,
html {
  font-family: Poppins;
  background-color: #f8f8f8;
}
a,
button,
p {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
#root {
  padding-bottom: 20;
}
.react-datepicker__input-container {
  height: 56px !important;
  border-radius: 10px;
  display: flex !important;
  flex: 1;
  max-width: 100% !important;
  width: 100% !important;
  input {
    border: 0px;
    display: flex;
    flex: 1;
    background-color: white;
    border: 1px solid lightgray !important;
    width: 100%;
    padding: 0 16px !important;
    height: 56px;
    border-radius: 10px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    padding: 0;
    :focus {
      border: 0 !important;
    }
  }
}
